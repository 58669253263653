
import {
  IonMenu,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonContent,
  IonListHeader,
  IonMenuToggle,
  IonIcon,
  IonFooter,
  IonButton,
  IonSelect,
  IonSelectOption,
IonText,
} from "@ionic/vue";
import { ActionTypes, useStore } from "../store";
import { useRouter } from 'vue-router';
import { locationOutline, home, happyOutline,bodyOutline, hammerOutline, bookOutline,
        calendar,peopleOutline, cartOutline, receiptOutline, colorPaletteOutline, } from 'ionicons/icons';
import { computed, onMounted, Ref, ref } from "vue";
import { EventSeasonInfo } from "@/models/EventSeasonInfo";
import { useUserStateUtils } from '@/common/UserStateUtils';
import { useAuthStore } from "@/store/authStore";
import { storeToRefs } from "pinia";
export default {
  name: "MenuComponent",
  components: {
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonList,
    IonItem,
    IonContent,
    IonListHeader,
    IonMenuToggle,
    IonIcon,
    IonLabel,
    IonText,
    IonFooter,
    IonButton,
    IonSelect,
    IonSelectOption,
    },
  setup(){
    const router = useRouter();
    const store = useStore();
    const authStore = useAuthStore();
    const {verifyAuth} = useUserStateUtils();
    const isActive = (_path: string) => {
      return router.currentRoute.value.path == _path;
    };

    const hasRoleInstructor = computed(() => { 
      return authStore.roles?.indexOf("Instructor") >= 0 ||
        authStore.roles?.indexOf("Admin") >= 0 ||
        authStore.roles?.indexOf("Merchant") >= 0;});    
    
    const cart = computed(() => store.getters.cart);

    const hasRoleMerchant = computed(() => { return authStore.roles?.indexOf("Merchant") >= 0;});
    const hasRoleCheckin = computed(() => { return authStore.roles?.indexOf("CheckinOnly") >= 0;});
    const hasRoleAdmins = computed(() => { return authStore.roles?.indexOf("Admin") >= 0;});

    const { expires, organizations } = storeToRefs(authStore)
    const currentOrganization = computed(() => store.state.organization);

    const seasonState: Ref<Array<EventSeasonInfo>> = ref([]);


    const logout = async () =>  {
        await authStore.logout();
        router.replace("/login");
    }

    const initSeasonList = async () => {
      if(!hasRoleInstructor.value)
        return;

      const r = await store.dispatch(ActionTypes.GET_EVENT_SEASONS,{type:"Lapset",status:"Open"});
      const r2 = await store.dispatch(ActionTypes.GET_EVENT_SEASONS,{type:"Lapset",status:"Presale"});
      seasonState.value = [ ...r.filter((s: EventSeasonInfo) => s.organizationId == currentOrganization.value.id), 
                            ...r2.filter((s: EventSeasonInfo) => s.organizationId == currentOrganization.value.id)];

    }

    onMounted( async () => {
      await initSeasonList();
      verifyAuth();
    });

    const colorOptions = () => {
      return [
        { value: undefined, label: 'Oletus' },
        { value: 'dark', label: 'Tumma' },
        { value: 'light', label: 'Vaalea' },
        ];
    };

    const selectOrganisation = (item: CustomEvent) => {
          if(item.detail.value){
            const o = organizations.value.find(i=>i.id == item.detail.value)
            if(o && o.id != currentOrganization.value.id){
              store.dispatch(ActionTypes.SELECT_ORG, o);
            }
          }
    };

    const selectColorTheme = (item: CustomEvent) => {
          if(item.detail.value){
              store.dispatch(ActionTypes.SELECT_THEME, item.detail.value);
          }
    };

    return {
        colorOptions,
        store,cart,
        isActive,
        logout,
        expires, organizations,
        router,
        hasRoleMerchant,hasRoleCheckin,hasRoleInstructor,hasRoleAdmins,
        selectOrganisation,selectColorTheme,
        currentOrganization,
        locationOutline,peopleOutline,receiptOutline,calendar,home,happyOutline,hammerOutline,bookOutline,bodyOutline,cartOutline,colorPaletteOutline,
        seasonState,
      }
  }
};
