import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { getNow, postNow, useStore } from ".";
import { TicketType } from "@/models/TicketType";
import { TicketViewModel } from "@/models/Ticket";

export const useTicketStore = defineStore('ticket', {
    state: () => ({
      ticketTypes: [] as TicketType[],
      organization: undefined as string|undefined,
    }),
  
    getters: {
    }, 
  
    actions: {
      async loadTicketTypes() {
        const org = useStore().state.organization;

        if(this.ticketTypes.length>0 && this.organization===org.id) 
          return this.ticketTypes;

        const params = new URLSearchParams([['organizationId', org.id]]);
        const r = await getNow(`TicketType`, params) as AxiosResponse<Array<TicketType>>;

        if(r.data.length>0) {
          this.organization = org.id;
          const sortedArray = r.data.sort((i, j) => i.name.localeCompare(j.name));
          this.ticketTypes = sortedArray;  
          return r.data;
        } else {
          throw r.data;
        }
      },

      async getTicketDetails(id: string) : Promise<TicketViewModel> {
        const r = await getNow(`Ticket/details/${id}`, undefined) as AxiosResponse<TicketViewModel>;
        const response = new TicketViewModel().fromResponseVm(r.data);
        return response;
      },


      async getTicketType(id: string) : Promise<TicketType> {
        // check if the ticket type is already loaded
        const t = this.ticketTypes.find(i=>i.id===id);
        if(t) return t;

        // Get the ticket type from the server
        const r = await getNow(`TicketType/${id}`, undefined) as AxiosResponse<TicketType>;
        return r.data;
      },

      async updateTicketType(ticketType: TicketType) : Promise<TicketType> {
        const r = await postNow(`TicketType/${ticketType.id}`, ticketType) as AxiosResponse<TicketType>;

        // Update the ticket type in the store
        const index = this.ticketTypes.findIndex(i=>i.id===ticketType.id);
        if(index>=0)
          this.ticketTypes[index] = r.data;
        else
          this.ticketTypes.push(r.data);

        return r.data;
      },

      async activateTicketType(id: string) : Promise<TicketType> {
        const r = await getNow(`TicketType/${id}/activate`, undefined) as AxiosResponse<TicketType>;
        return r.data;
      },

      async deactivateTicketType(id: string) : Promise<TicketType> {
        const r = await getNow(`TicketType/${id}/deactivate`, undefined) as AxiosResponse<TicketType>;
        return r.data;
      },

      async organisationChanged() {
        this.ticketTypes = [];
        this.organization = undefined;
      },

    }
  })