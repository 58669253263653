
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonLabel,
  IonItem,
  IonButton,
  IonList,
  loadingController
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, ActionTypes } from "../store";
import { RegisterEventTemplate } from "@/models/RegisterEventTemplate";
import { TicketInfo } from "@/models/TicketInfo";
import { useErrorBox } from "@/components/errorBox";

export default defineComponent({
  name: "RegisterTo",
  data() {
    return {
      searchTerm: "",
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonLabel,
    IonItem,
    IonButton,
     IonList,

  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();
    const id = router.currentRoute.value.params["id"] as string;

    const eventTemplate: Ref<RegisterEventTemplate|null> = ref(null);
    const ticket: Ref<TicketInfo|null> = ref(null);

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }    



  onMounted(async () => {
    if(!id)
      return;

    const l = await loading();
    try {
      const result = await store.dispatch(ActionTypes.REGISTER_TO_EVENT_TEMPLATE,id);
      eventTemplate.value = result;
      console.log(result);
    } catch (err: any){
      console.log(err);
      showError(err as string,  "virhe");
    } 
    finally {    
      l.dismiss();
    }      
   });

   
    const selectTicket = (item: TicketInfo) => {
      ticket.value = item;
    };

    const addToCart = (checkOut: boolean) => {
      if(eventTemplate.value === null || eventTemplate.value.id === null || ticket.value === null || ticket.value.id === null)
        return;
      store.dispatch(ActionTypes.ADD_TO_CART, {
        eventId: eventTemplate.value.id,
        ticketId: undefined,
        ticketTypeId: ticket.value.id,
        comments: "",
        discount: 0,
        id: undefined,
        name: ticket.value.title,
        price: ticket.value.price,
        quantity: 1,
        memberId: undefined,
      });
      if(checkOut){
            router.replace('/checkout/');
      } else {
        router.back();
      }
    };

    return {
      router,
      eventTemplate,
      selectedTicket: ticket,
      selectTicket,
      addToCart
    };
  },
});
