import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (!$setup.hasRoleCheckin)
    ? (_openBlock(), _createBlock(_component_ion_menu, {
        key: 0,
        "content-id": "main",
        side: "start",
        "menu-id": "main-menu",
        id: "main-menu"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, { color: "primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Eport")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          ($setup.expires)
            ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_menu_toggle, { "auto-hide": false }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            button: "",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.replace('/')))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: $setup.home }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    class: _normalizeClass([{boldLabel : $setup.isActive('/') }, 'menu-label'])
                                  }, "Etusivu", 2)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, { "auto-hide": false }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            button: "",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.replace('/main/calendar')))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: $setup.calendar }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    class: _normalizeClass([{boldLabel : $setup.isActive('/main/calendar') }, 'menu-label'])
                                  }, "Kalenteri", 2)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, { "auto-hide": false }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            button: "",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.replace('/main/trainingPrograms')))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: $setup.bookOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    class: _normalizeClass([{boldLabel : $setup.isActive('/main/trainingPrograms') }, 'menu-label'])
                                  }, "Ohjelmoinnit", 2)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      ($setup.hasRoleInstructor)
                        ? (_openBlock(), _createBlock(_component_ion_list_header, { key: 0 }, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, [
                                _createVNode(_component_ion_text, { color: "primary" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Henkilökunta")
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleInstructor)
                        ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            key: 1,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                button: "",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.replace('/customers')))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.peopleOutline }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([{boldLabel : $setup.isActive('/customers') }, 'menu-label'])
                                      }, "Asiakkaat", 2)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleMerchant)
                        ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            key: 2,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                button: "",
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.replace('/cashRegister')))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.receiptOutline }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([{boldLabel : $setup.isActive('/cashRegister') }, 'menu-label'])
                                      }, "Kassa", 2)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleMerchant)
                        ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            key: 3,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                button: "",
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.replace('/shoppingCartsPage')))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.receiptOutline }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([{boldLabel : $setup.isActive('/shoppingCartsPage') }, 'menu-label'])
                                      }, "Kuitit", 2)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleMerchant)
                        ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            key: 4,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                button: "",
                                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.replace('/salesOfDay')))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.receiptOutline }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([{boldLabel : $setup.isActive('/salesOfDay') }, 'menu-label'])
                                      }, "Kassan tarkistus", 2)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleMerchant)
                        ? (_openBlock(), _createBlock(_component_ion_list_header, { key: 5 }, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, [
                                _createVNode(_component_ion_text, { color: "primary" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Ylläpito")
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleMerchant)
                        ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            key: 6,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                button: "",
                                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.replace('/listUserCategories')))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.receiptOutline }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([{boldLabel : $setup.isActive('/listUserCategories') }, 'menu-label'])
                                      }, "Asiakasryhmät", 2)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleMerchant)
                        ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            key: 7,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                button: "",
                                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$router.replace('/listProducts')))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.receiptOutline }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([{boldLabel : $setup.isActive('/listProducts') }, 'menu-label'])
                                      }, "Tuotteet", 2)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleMerchant)
                        ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            key: 8,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                button: "",
                                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$router.replace('/listInfoPages')))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.receiptOutline }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([{boldLabel : $setup.isActive('/listInfoPages') }, 'menu-label'])
                                      }, "Infosivut", 2)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleMerchant)
                        ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            key: 9,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                button: "",
                                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$router.replace('/admin/tickettypes')))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.receiptOutline }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([{boldLabel : $setup.isActive('/admin/tickettypes') }, 'menu-label'])
                                      }, "Korttityypit", 2)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleAdmins)
                        ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            key: 10,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                button: "",
                                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$router.replace('/doorActions')))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.receiptOutline }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([{boldLabel : $setup.isActive('/doorActions') }, 'menu-label'])
                                      }, "Ovikeskukset", 2)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.hasRoleAdmins)
                        ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                            key: 11,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                button: "",
                                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$router.replace('/listSqlRequests')))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, { icon: $setup.receiptOutline }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createElementVNode("span", {
                                        class: _normalizeClass([{boldLabel : $setup.isActive('/listSqlRequests') }, 'menu-label'])
                                      }, "Raportit", 2)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      ($setup.seasonState.length>0)
                        ? (_openBlock(), _createBlock(_component_ion_list_header, { key: 12 }, {
                            default: _withCtx(() => [
                              _createElementVNode("h2", null, [
                                _createVNode(_component_ion_text, { color: "primary" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode("Kausilinkit")
                                  ]),
                                  _: 1
                                })
                              ])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.seasonState, (seasonEvent) => {
                        return (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                          key: seasonEvent.id,
                          "auto-hide": false
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, {
                              button: "",
                              onClick: ($event: any) => (_ctx.$router.replace('/listevents/' + seasonEvent.categoryId))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: $setup.happyOutline }, null, 8, ["icon"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("span", {
                                      class: _normalizeClass([{boldLabel : $setup.isActive('/listevents/'+ seasonEvent.categoryId) }, 'menu-label'])
                                    }, _toDisplayString(seasonEvent.name) + " " + _toDisplayString(seasonEvent.status === 'Presale' ? '(ennakko)' : ''), 3)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["onClick"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128)),
                      _createVNode(_component_ion_menu_toggle, { "auto-hide": false }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: $setup.colorPaletteOutline }, null, 8, ["icon"]),
                              _createTextVNode("  "),
                              _createVNode(_component_ion_select, {
                                value: $setup.store.state.theme,
                                interface: "popover",
                                onIonChange: $setup.selectColorTheme
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.colorOptions(), (item) => {
                                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                                      key: item.value,
                                      value: item.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.label) + " ulkoasu", 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "onIonChange"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_menu_toggle, { "auto-hide": false }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: $setup.locationOutline }, null, 8, ["icon"]),
                              _createTextVNode("  "),
                              _createVNode(_component_ion_select, {
                                class: "select-full-width",
                                interface: "popover",
                                value: $setup.currentOrganization.id,
                                "selected-text": $setup.currentOrganization.name,
                                onIonChange: $setup.selectOrganisation
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.organizations, (item) => {
                                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                                      key: item.id,
                                      value: item.id
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.name), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value", "selected-text", "onIonChange"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.expires || $setup.cart.rows.length>0 )
            ? (_openBlock(), _createBlock(_component_ion_footer, {
                key: 1,
                style: {"text-align":"center"}
              }, {
                default: _withCtx(() => [
                  ($setup.cart.rows.length>0)
                    ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                        key: 0,
                        "auto-hide": false
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            button: "",
                            onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$router.replace('/checkout')))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: $setup.cartOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    class: _normalizeClass([{boldLabel : $setup.isActive('/checkout') }, 'menu-label'])
                                  }, "Ostoskori (" + _toDisplayString($setup.cart.rows.length) + ")", 3)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  ($setup.expires)
                    ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                        key: 1,
                        "auto-hide": false
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, {
                            button: "",
                            onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.$router.replace('/main/profile')))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: $setup.bodyOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("span", {
                                    class: _normalizeClass([{boldLabel : $setup.isActive('/main/profile') }, 'menu-label'])
                                  }, "Profiili", 2)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  ($setup.expires)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 2,
                        color: "primary",
                        fill: "solid",
                        expand: "full",
                        onClick: _cache[15] || (_cache[15] = ($event: any) => ($setup.logout()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Kirjaudu ulos")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}