
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonText,
  IonDatetime,
  IonDatetimeButton,
  loadingController,
IonModal,
IonItemDivider,
IonMenuButton,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref, } from "vue";
import { useRouter } from "vue-router";
import { mailOutline, bookmarkOutline, cashOutline,trashOutline, locationOutline, logInOutline, checkmarkOutline, journalOutline, personOutline, personAddOutline, arrowForwardOutline, saveOutline } from "ionicons/icons";
import { useStore, ActionTypes, } from "../store";
import { FamilyMemberDetailedViewModel, ShoppingCartSimpleViewModel, UserDetailedViewModel } from "@/models/UserDetailedViewModel";
import { TicketInfo } from "@/models/Ticket";
import { useErrorBox } from "@/components/errorBox";
import TicketButton from "@/components/TicketButton.vue";
import * as moment from 'moment-timezone';

export default defineComponent({
  name: "ProfileView",

  data() {
    return {
      processing: false,
      naUser: "",
      naEmail: "",
      mtUser: "",
    };
  },
  components: {
    // IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonItem,
    // IonList,
    IonIcon,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    // IonMenu,
    // CardEdit,
    // IonModal,
    IonLabel,
    IonText,
    IonDatetime,
    // IonTextarea,
    // IonCheckbox,
    TicketButton,
    IonModal,
    IonDatetimeButton,
    // IonSelect,
    // IonSelectOption,
    IonItemDivider,
    IonMenuButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const momentjs: any = moment;
    const {showError} = useErrorBox();
    const customer: Ref<UserDetailedViewModel|undefined> = ref(undefined);
    const currentMember: Ref<FamilyMemberDetailedViewModel|undefined> = ref(undefined);

    const history: Ref<Array<ShoppingCartSimpleViewModel>> = ref([]);

    const activeOnly: Ref<boolean> = ref(true);

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }    

    const loadProfile = async () => {

      const id = store.state.fullProfile?.userId;
      if(!id) return;

      const l = await loading();
      try {
        const profine = await store.dispatch(ActionTypes.GET_FULL_PROFILE, id);
        if(profine?.userId){
          customer.value = profine; 
          }

        // history.value = await store.dispatch(ActionTypes.GET_CART_HISTORY, id);

      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    }

    onMounted(async () => {
      await loadProfile();
    });


    const save = async () => {
      if(!customer.value)
        return false;

      const l = await loading();

      try {
        const r = await store.dispatch(ActionTypes.UPDATE_FULL_PROFILE, customer.value);
        if(r?.userId) { customer.value = r; }
         l.dismiss();
        return true;
      } catch(err) {
        //customer.value = oldValue; 
        const a = await showError(err as string, "virhe");
        l.dismiss();
        await a.onDidDismiss();        
        return false;
      }
    };

    const addMember = () => {
      const member = new FamilyMemberDetailedViewModel();
      customer.value?.familyMembers.push(member)
      };

      const activeTickets = (items: TicketInfo[]) => {
            return items.filter(i=>i.active || i.reserved).length;
            };


      const orderedTickets = (items: TicketInfo[]) => {
            return items
            .filter(i=>i.active || i.reserved || !activeOnly.value)
            .sort((a: TicketInfo,b: TicketInfo)=> (a.from?.valueOf()??0) - (b.from?.valueOf()??0));
            };     
        
    const selectDate = (fm: FamilyMemberDetailedViewModel, e: CustomEvent<any>)=> {
      fm.birthDay = e.detail.value;
    };


  const removeFamilyMember = async () => {
    if(!currentMember.value)
        return;
    const l = await loading();
    try
    {
      await store.dispatch(ActionTypes.REMOVE_FAMILY_MEMBER, {userId:customer.value?.userId ?? -1,familyMemberId:currentMember.value.id});
      currentMember.value = undefined; 
      if(customer.value?.userId)
        await loadProfile(); 
    } catch(err){
      showError(err as string, "virhe");
    } finally{
      l.dismiss();
    }
  }


    return {
      history,momentjs,
      router,
      customer,activeTickets,
      bookmarkOutline,personOutline, personAddOutline, arrowForwardOutline,checkmarkOutline,logInOutline,locationOutline,cashOutline,trashOutline,
      mailOutline,
      addMember,removeFamilyMember,
      currentMember,
      selectDate,save,activeOnly,
      orderedTickets,saveOutline,journalOutline
    };
  }
});
