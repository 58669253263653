
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonList,
  IonItem,
  // handleAlert,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { logInOutline,batteryFullOutline} from "ionicons/icons";
import { useSessionObject } from "@/store";
import { useErrorBox } from "@/components/errorBox";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "RegisterView",
  data() {
    return {
      loginInfo: { username: "", password: "", password2: "" },
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
  },
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const session = useSessionObject();
    const {showError} = useErrorBox();

    const registerNow = async (p: any) => {
      try {
        if(p.password != p.password2){
          showError("Tarkista salasanat",  "virhe");
          return;
        }
        if(p.password.length < 6 ){
          showError("Salasana on liian lyhyt",  "virhe");
          return;
        }

        await authStore.register(p.username,p.username, p.password, "");
        if (session.enteredRoute)
          router.replace(session.enteredRoute)
        else
          router.replace("/main/home");
        //console.log(ret);
      } catch (err) {
        //console.log(err);
        showError(err as string,  "virhe");
      }
    };

    return {
      router,
      registerNow,
      logInOutline,
      batteryFullOutline
    };
  },
});
