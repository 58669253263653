
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonButton,
  IonRow,
  IonCol,
  IonText,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore, ActionTypes, } from "../store";
import { useErrorBox } from "@/components/errorBox";
import { UserCategoryFullInfo } from "@/models/UserDetailedViewModel";

export default defineComponent({
  name: "EditUserCategory",
  data() {
    return {
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonItem,
    IonButton,
    IonRow,
    IonCol,
    IonText,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const {showError} = useErrorBox();
    const category: Ref<UserCategoryFullInfo|undefined> = ref(undefined);

    onMounted(() => {
      const id = router.currentRoute.value.params["id"].toString();

      store.dispatch(ActionTypes.GET_CATEGORY, id)
        .then((result) => {
          category.value = result;
        })
        .catch((err) => {
          showError(err, "virhe");
      });
    });
    

    const save = () => {
      if(!category.value)
        return;

      store.dispatch(ActionTypes.UPDATE_CATEGORY, category.value)
        .then((result) => {
          category.value = result;
        })
        .catch((err) => {
          showError(err, "virhe");
      });
    }

    return {
      save,
      category,
    };
  }
});
