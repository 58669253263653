import { useSessionObject } from '@/store';
import { useAuthStore } from '@/store/authStore';
import { ref, Ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export function useUserStateUtils () {

  const processing: Ref<boolean> = ref(false);
  const verifyAuth = () => { 
    const router = useRouter();
    const authStore = useAuthStore();
    const session = useSessionObject();

    const hasRoleCheckin = computed(() => { return authStore.roles?.indexOf("CheckinOnly") >= 0;});

    processing.value = true;

    const logged = authStore.checkAuth();

        if(logged){
          // Logged in. Is login screen only
          if(hasRoleCheckin.value){
            switch (router.currentRoute.value.name){
              case "CheckIn":
              case "CardRead":
              break;
              default:
              router.replace("/cardRead");
              break;
            }            
          }
          return;
        }

        // Not logged in. Is login required?
        switch (router.currentRoute.value.name){
          case "ResetPassword":
          case "Register":
          case "RegisterTo":
          case "ResetWithToken":
          case "Purchase":
          case "CheckOut":
          case "CheckOut2":
          case "CheckOut3":
              break;

          // Move to login
          default:
            session.enteredRoute = router.currentRoute.value.fullPath;
            // debugger;
            router.replace("/login");
          break;
        }
      }
    
  
  return {
    verifyAuth, 
    processing,
  }
}