
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonList,
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonFab,
  IonFabButton,
  IonText,
  loadingController,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, } from "vue";
import { useRouter } from "vue-router";
import { personOutline, personAddOutline, logInOutline, cartOutline, syncOutline, } from "ionicons/icons";
import { UserLogin, useSessionObject, useStore } from "@/store";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "LoginView",
  data() {
    return {
      loginInfo: { username: "", password: "" }
    };
  },
  components: {
    // IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonGrid,
    IonRow,
    IonCol,
    IonSelect,
    IonSelectOption,
    IonFab,
    IonFabButton,
    IonText,
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();
    const store = useStore();

    const session = useSessionObject();
    const cart = computed(() => store.getters.cart);

    const { t, n, availableLocales, locale, te } = useI18n<{ message: MessageSchema; number: NumberSchema; }>({
      useScope: "global",
      // inheritLocale: true,
    });

    const { showError } = useErrorBox();


    const loading = async () => {
      const loading = await loadingController.create({
        message: t("processing"),
      });

      await loading.present();
      return loading;
    };

    onMounted(async () => {
      locale.value = auth.locale as any;
      auth.checkAuth();
    });

    const loginNow = async (p: UserLogin) => {
      const l = await loading();      
      try {
        await auth.login(p.username, p.password, true);
        l.dismiss();

        if(auth.roles.indexOf("CheckinOnly") >= 0)
          router.replace('/cardRead');
        else if (session.enteredRoute && session.enteredRoute != "/login"){
          router.replace(session.enteredRoute);
        }
        else
          router.replace('/main/home');
          
        auth.preAuthUrl = "";
      } catch (err) {        

        if(te(err as string))
          showError(t(err as string), t("global.Error"));
        else
          showError(err as string, t("global.Error"));
      } finally {
        l.dismiss();
      }

    };

    const localeChanged = async () => {
      auth.locale = locale.value;
    };


    return {
      router,
      loginNow,
      logInOutline,
      personOutline,
      personAddOutline,
      cartOutline,
      syncOutline,
      localeChanged,
      t,
      n,
      availableLocales,
      locale,
      cart,
    };
  },
});
