
//import { IonContent, IonHeader, IonTitle, IonToolbar, IonInput,IonButton } from '@ionic/vue';
import { defineComponent, ref, Ref, computed} from 'vue';
import { EventInstanceViewModel } from '@/models/EventInstanceViewModel'
import momentjs from 'moment'
import { NewVisitModel } from '@/models/NewVisitModel';
import { ReservationViewModel } from '@/models/ReservationViewModel';
import { useStore } from '@/store';
import { ActionTypes } from '../store'
import { useErrorBox } from '@/components/errorBox';

export default defineComponent({
  name: 'EventInstanceButton',
    props: {
    eventInstance: { 
      type: EventInstanceViewModel,
      required: false
      },
    newVisit: { 
      type: NewVisitModel,
      required: false
      },
    reservationVm: { 
      type: ReservationViewModel,
      required: false
      }
  },
  emits:
    ['visitclick','instanceclick','reservationclick']
  ,

  setup(props, context ) {
        
    const store = useStore();
    const {showError} = useErrorBox();
    const currentInstance: Ref<EventInstanceViewModel|undefined> = ref(props.eventInstance);
    const currentVisit: Ref<NewVisitModel|undefined> = ref(props.newVisit);
    const currentReservation: Ref<ReservationViewModel|undefined> = ref(props.reservationVm);
    const currentTicket: Ref<string|undefined> = ref(undefined);
    const error: Ref<string|undefined> = ref(undefined);

    if(currentVisit.value) {
      currentInstance.value = currentVisit.value.eventInstance;
    }

    if(!currentInstance.value && currentReservation.value && currentReservation.value.eventInstanceId){
        store.dispatch(ActionTypes.GET_EVENT_INSTANCE, currentReservation.value.eventInstanceId)
        .then((result) => { 
          store.dispatch(ActionTypes.IS_VISIT_ALLOWED,{userId: currentReservation.value?.userId ?? -1, memberId: currentReservation.value?.familyMemberId ?? "", eventInstanceId: currentReservation.value?.eventInstanceId ?? "", reservationId: currentReservation.value?.id, later: false})
          .then((result2) => { currentInstance.value = result; currentTicket.value = result2; })
          .catch((err) => {currentInstance.value = result;error.value = " \"" + err + "\"" ; console.debug(err);});          
        }).catch((err) => { showError(err, "virhe"); });
    }
  
    const btnClick  = ()  => {
      if(error.value){
        showError(error.value, "virhe");
      return;
      }

      if(currentReservation.value){
        context.emit('reservationclick',{
          reservation:currentReservation.value, 
          instance: currentInstance.value, 
          ticketId: currentTicket.value } );
      } else if(currentVisit.value){
        context.emit('visitclick', currentVisit.value);
      } else {
        context.emit('instanceclick', currentInstance.value);
      } 
    };
    
    const spaceTxt = computed(() => {
      const instance = currentInstance.value;

      if(!instance){
        return "";        
      }
      if (instance.participants == null){
          return "";
      }
       if (currentVisit.value && currentVisit.value.reservation){
          return "varattu";
      }      
      if (!instance.spaceAvailable)
        return "täynnä";
      const tilaa = instance.participants - (instance.visits ?? 0);
      if (tilaa > 9)
        return "";
      return "(" + tilaa + " paikkaa)";
    });
  
    const timeTxt = computed(() => {
            if(!currentInstance.value){
        return "";        
      }
      return momentjs(currentInstance.value.beginTime).local().format("H:mm") + " - " + momentjs(currentInstance.value.endTime).format("H:mm");  
    });

    return { 
      currentInstance, 
      btnClick, 
      spaceTxt, 
      timeTxt, 
      currentReservation,error }
  }
});

