
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonList,
  IonItem,
  IonText,
loadingController,
} from "@ionic/vue";
import { defineComponent, ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { logInOutline} from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useI18n } from "vue-i18n";
import { MessageSchema, NumberSchema } from "@/main";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "ResetPassword",
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonText,
  },
  setup() {
    const router = useRouter();
    const auth = useAuthStore();
    const {showError} = useErrorBox();
    const email: Ref<string> = ref("");

    const { t, n } = useI18n<{ message: MessageSchema; number: NumberSchema }>({
      useScope: "global",
    });

    const loading = async () => {
      const loading = await loadingController.create({
        message: t("processing"),
      });

      await loading.present();
      return loading;
    };
    
    const resetNow = async (emailStr: string) => {
      if (emailStr.length < 4) {
        showError(t("resetPasswd.emptyErr"), t("global.Error"));
        return;
      }

      const l = await loading();
      try {
        auth.requestPasswordResetToken(emailStr);
        showError(t("resetPasswd.okMsg"), t("global.Note"));
      } catch (err) {
        showError(err as string, t("global.Error"));
      } finally {
        l.dismiss();
      }      
    };

    return {
      router,
      resetNow,
      logInOutline,
      email,
      t,
      n,
    };
  },
});
