
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonButton,
  IonMenuButton,
  IonGrid,
  IonRow,
  IonCol,
  IonText,
  IonCheckbox,
} from "@ionic/vue";

import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/userStore";
import { useErrorBox } from "@/components/errorBox";
import { MessageDto } from "@/models/core/Messaging";

import moment from 'moment-timezone';

export default defineComponent({
  name: "ListMessages",
  data() {
    return {
      searchTerm: "",
      processing: false,
    };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonButton,
    IonMenuButton,
    IonGrid,
    IonRow,
    IonCol,
    IonText,
    // IonInput,
    IonCheckbox,
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const {showError} = useErrorBox();

    const allMessages: Ref<Array<MessageDto>> = ref([]);
    const showAll: Ref<boolean> = ref(false);
    // const filterTxt: Ref<string|undefined> = ref(undefined);

    const momentJs = (a: any): moment.Moment => { return moment(a);}
    onMounted( async() => {

      try {
        allMessages.value = await userStore.getMessages(true,20);
      } catch (error) {
        showError(error as string,  "virhe");
      }
 });

    //  const filteredProducts = computed(() => {
    //    const lowerSearch = filterTxt.value?.toLowerCase();  
    //    return allProducts.value.filter(s => (showAll.value || s.isActive) && (
    //      !lowerSearch || lowerSearch.length == 0 || 
    //      s.name?.toLowerCase().indexOf(lowerSearch) >= 0 || 
    //      s.barcode?.toLowerCase().indexOf(lowerSearch) >= 0 || 
    //      s.code?.toLowerCase().indexOf(lowerSearch) >= 0))
    //     .sort((a: any, b: any) => a.orderKey - b.orderKey);
    // });
   
    const showMessage = (item: MessageDto) => {
        if(item){
            router.push(`/message/${item.id}`);
      }
    };

    return {
      router,
      showAll,
      allMessages,
      showMessage,
      momentJs,
    };
  },
});
