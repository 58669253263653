
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useErrorBox } from "@/components/errorBox";
import { MessageDto } from "@/models/core/Messaging";
import { useUserStore } from "@/store/userStore";

export default defineComponent({
  name: "ShowMessagePage",
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonItem,
    // IonButton,
    IonRow,
    IonCol,
    // IonText,
    // IonTextarea,
    // IonCheckbox,
    // IonSelect,
    // IonSelectOption,
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const {showError} = useErrorBox();
    const message: Ref<MessageDto|undefined> = ref(undefined);

    onMounted( async () => {
      const id = router.currentRoute.value.params["id"].toString();
      try {
        message.value = await userStore.getMessage(id);
      } catch (error) {
        showError(error as string,  "virhe");
      }
    });
    

    return {
      message,
    };
  }
});
