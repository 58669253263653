
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonButton,
  IonText,
  loadingController,
  IonSelect,
  IonSelectOption,
  IonCheckbox,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { saveOutline } from "ionicons/icons";
import { useStore,  ActionTypes } from "@/store";
import { useErrorBox } from "@/components/errorBox";
import { useTicketStore } from "@/store/ticketStore";
import { TicketType } from "@/models/TicketType";
import { OrganizationInfo } from "@/models/Organization";
import { DiscountDto, DiscountGroupDto } from "@/models/Discount";
import { useFinancialStore } from "@/store/financialStore";
import { VatGroupViewModel } from "@/models/ProductViewModel";
import { FinAccountModel } from "@/models/ShoppingCart";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { useCalendarStore } from "@/store/calendarStore";

import moment from 'moment-timezone';
import { stringToHash } from "@/utils/changeTracking";

export default defineComponent({
  name: "TicketTypeDetailsPage",
  data() {
    return {
      processing: false,
    };
  },
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonItem,
    IonButton,
    IonText,
    IonIcon,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    IonDatetimeButton,
    IonDatetime,
    IonModal,
    IonLabel,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const ticketStore = useTicketStore();
    const financialStore = useFinancialStore();
    const calendarStore = useCalendarStore();

    const {showError} = useErrorBox();
    const ticketType: Ref<TicketType|undefined> = ref(undefined);
    const organizations: Ref<Array<OrganizationInfo>> = ref([]);
    const discounts: Ref<Array<DiscountDto>> = ref([]);
    const discountGroups: Ref<Array<DiscountGroupDto>> = ref([]);
    const vatGroups: Ref<Array<VatGroupViewModel>> = ref([]);
    const accounts: Ref<Array<FinAccountModel>> = ref([]);
    const allCategories: Ref<Array<EventCategoryViewModel>> = ref([]);

    const momentjs = (a: any): moment.Moment => { return moment(a); }

    const loading = async () => {
      const loading = await loadingController.create({ message: 'Hetki...', duration: undefined, });
      await loading.present();
      return loading;
    }    


    onMounted(async () => {
      const id = router.currentRoute.value.params["id"].toString();
      const l = await loading();
      try {

        organizations.value = await store.dispatch(ActionTypes.GET_ORGANIZATIONS, undefined);
        discounts.value = await financialStore.getDiscounts();
        discountGroups.value = await financialStore.getDiscountGroups();
        accounts.value = await financialStore.getFinAccounts();        
        vatGroups.value = await financialStore.getVatGroups();
        allCategories.value = await calendarStore.getCategories(store.state.organization.id);

        if(!id || id === ""|| id === "new"){
          ticketType.value = new TicketType();
          savedHash.value = hashComponentState.value;
          return;
        }

        ticketType.value = await ticketStore.getTicketType(id);
        savedHash.value = hashComponentState.value;

      } catch(err){
        showError(err, "virhe");
      } finally{
        await l.dismiss();
      }
    });
    

    const save = async () => {
      if(!ticketType.value)
        return;

      const l = await loading();
      try {
        ticketType.value = await ticketStore.updateTicketType(ticketType.value);
        savedHash.value = hashComponentState.value;
      } catch(err){
        showError(err, "virhe");
      } finally{
        await l.dismiss();
      }
    }

    const discountFiltered = computed(() => {
      if(!ticketType.value)
        return [];

      return discounts.value
        .filter(d => !d.expired || ticketType.value!.discounts?.find(td => td === d.id))
        .sort((a,b)=>a.name.localeCompare(b.name));
    });

    const categoriesFiltered = computed(() => {
      if(!ticketType.value)
        return [];

      return allCategories.value
        .filter(c => c.active || ticketType.value!.categories?.find(tc => tc === c.id))
        .sort((a,b)=>(a.title ?? '').localeCompare(b.title ?? ''));        
    });

    const getForIonDateTime = (date:Date|string|undefined) => {
      return momentjs(date).utc(true).toISOString();
    }

    const setFromIonDateTime = (date:any) => {
      return momentjs(date).toISOString();
    }

        //#region isDirty check for relative simple components
        const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify({
        ticketType: ticketType.value,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion


    return {
      save, getForIonDateTime, setFromIonDateTime,
      ticketType, isDirty,
      saveOutline,
      organizations,
      discountFiltered, discountGroups, vatGroups, accounts, categoriesFiltered,
    };
  }
});
