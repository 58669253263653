import { defineStore } from 'pinia'
import { RemovableRef, useStorage } from '@vueuse/core'
import { ActionTypes, client, MutationTypes, postNow, prettyResponse, useStore } from '.';
import { AxiosResponse } from 'axios';
import { LoginResponse } from '@/models/Login';
import jwtDecode from 'jwt-decode';
import { useUserStore } from './userStore';
import { OrganizationInfo } from '@/models/Organization';
import { UserDetailedViewModel } from '@/models/UserDetailedViewModel';

// Can be removed if no use. Pinia will type it automatically with TypeScript anyway.
interface UserState {
  username: RemovableRef<string>; 
  id: RemovableRef<number>;
  authToken: RemovableRef<string>;
  organizations: RemovableRef<OrganizationInfo[]>; 
  roles: RemovableRef<string[]>;
  issued: RemovableRef<number|undefined>;
  expires: RemovableRef<number|undefined>;
  locale: RemovableRef<string>; 
  preAuthUrl: RemovableRef<string>; 
}

export type UserLogin = {
  username: string; 
  password: string}

export type ResetPasswordModel = {
  token: string;
  userName: string;
  password: string;
}

export type VerifyEmailModel = {
  token: string;
  userName: string;
}


export const useAuthStore = defineStore('auth', {
  state: ():UserState => ({
    username: useStorage("username", ""),
    id: useStorage("id", 0),
    authToken: useStorage("authToken", ""),
    organizations: useStorage("organizations",[]),
    roles: useStorage("roles",[]),
    issued: useStorage("issued",undefined),
    expires: useStorage("expires",undefined),
    locale: useStorage("locale","fi-FI"),
    preAuthUrl: useStorage("preauthUrl",""),
  }),

  getters: {
    isAuthenticated: (state) => {
      return state.username !== "" && state.authToken !== "";
    },
    isMemberOf: (state) => (role: string) => {
      return state.roles.indexOf(role) > -1;
    },
  }, 

  actions: {
    logout() {
      this.username = "";
      this.id = 0;
      this.authToken = "";
      this.organizations = [];
      this.roles = [];
      this.expires = undefined;
      this.issued = undefined;
    }, 

    checkAuth() {
      const noe = Date.now() / 1000;
      if(!this.expires) {
          // not authenticated
          return false;
      } else if(this.expires > noe){
          // ok
          return true;
      } else if (this.expires) {
          // expired
          this.logout();
          return false;
      }
      return false;
    },

    async login(username: string, password: string, waitAll: boolean){
      try {
        const r = await postNow("Users/authenticate",{
          username:username, password:password}) as AxiosResponse<LoginResponse>;

          if(r.status === 200 && r.data.id && r.data.username && r.data.organizations && r.data.jwtToken){
            const token = jwtDecode(r.data.jwtToken) as any;
            this.username = r.data.username;
            this.id = r.data.id;
            this.organizations = r.data.organizations;
            this.authToken = r.data.jwtToken;
            this.roles = r.data.roles ?? [];
            this.issued = token.iat;
            this.expires = token.exp;

            const store = useStore();
           
            // Refresh all data
            const p1 = store.dispatch(ActionTypes.REFRESH_FULL_PROFILE,false);
            const p2 = store.dispatch(ActionTypes.REFRESH_CART, undefined);
            const p3 = store.dispatch(ActionTypes.REFRESH_VISITS, undefined);
           
            const userStore = useUserStore();
            const p4 = client.send("listenForActions", userStore.browserId, r.data.jwtToken);
           
            if(waitAll){
              Promise.all([p1, p2, p3, p4]).then((_values) => {
                return(this);
              });
            } else { return(this); }
                                     
            
          } else {
            throw (r as any).data.Message;
          }          

      } catch (error) {

        throw (prettyResponse(error,(message: any)=>{
          switch(message){
            case "Network Error":
              return "login.networkErr";
            case "Username or password is incorrect":
              return "login.userNameOrPasswordIsInvalidErr";
            case "The Password field is required.":
              return "login.passwordIsRequiredErr";
            case "The Username field is required.":
              return "login.usernameIsRequiredErr";
            default:
              return message;
          }
        }));

      }
    },

    async requestPasswordResetToken(username: string){
      try {
        const url = `${location.protocol}//${location.host}/resetWithToken`;
        const r = await postNow(`Users/PasswordResetEmail` ,{ username:username,resetUrl:url}) as AxiosResponse<boolean>;
        if(r.status !== 200)
          throw "error";
        
      } catch (error) {
        throw (prettyResponse(error,(message: any)=>{
          switch(message){
            case "Email not found":
              return "Käyttäjätunnusta tai sähköpostia ei löytynyt!";
              default:
                return message;
          }
        }));
      }
    },

    async resetPasswordWithToken(token: string, username: string, password: string){
      try {
        const r = await postNow(`Users/ResetPasswordWithToken`,{
          password:password,
          token:token, 
          userName: username
        }) as AxiosResponse<LoginResponse>;

        if(r.status === 200 && r.data.id && r.data.username && r.data.organizations && r.data.jwtToken){
          const token = jwtDecode(r.data.jwtToken) as any;
            this.username = r.data.username;
            this.id = r.data.id;
            this.organizations = r.data.organizations;
            this.authToken = r.data.jwtToken;
            this.roles = r.data.roles ?? [];
            this.issued = token.iat;
            this.expires = token.exp;


          return;
        } else {
          throw (r as any).data;
         }

      } catch (error) {
        throw (prettyResponse(error,(message: any)=>{
          switch(message){
            case "userName is in use":
              return "K�ytt�j�tunnus tai s�hk�posti on jo k�yt�ss�.";
              default:
                return message;
          }
        }));
      }
    },

    async verifyEmailWithToken(token: string, username: string){
      try {        
        await postNow(`Eport/UserApi/VerifyEmailWithToken`,{
          token:token, 
          userName: username,
        }) as AxiosResponse<LoginResponse>;

      } catch (error) {
        throw(prettyResponse(error,(message)=>{
          switch(message){
            case "Invalid token":
              return "register.invalidTokenErr";
            default:
              return message;
          }
        }));
      }
    },

    async requestEmailVerifyToken(username: string){
      const verifyEmailUrl = `${location.protocol}//${location.host}/verifyEmail`;

      try {        
        await postNow(`Eport/UserApi/RequestEmailVerifyToken`,{
          userName: username,
          verifyEmailUrl: verifyEmailUrl,
        }) as AxiosResponse<LoginResponse>;

      } catch (error) {
        throw(prettyResponse(error,(message)=>{
          switch(message){
            case "Invalid token":
              return "register.invalidTokenErr";
            default:
              return message;
          }
        }));
      }
    },

    async register(username: string, email: string, password: string, phone: string){
      const verifyEmailUrl = `${location.protocol}//${location.host}/verifyEmail`;
      try {
        const r = await postNow(`Users/register`, { 
          username:username, email:email, password:password, verifyEmailUrl, phone:phone
        }) as AxiosResponse<LoginResponse>;
        
        if(r.status === 200&& r.data.id && r.data.username && r.data.jwtToken){
          const roleClaim = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
          const token = jwtDecode(r.data.jwtToken) as any;
          this.username = r.data.username;
          this.id = r.data.id;
          this.authToken = r.data.jwtToken;
          this.roles = token[roleClaim] ?? [];
          this.expires = token.exp;
          return;
        } else { 
          throw (r as any).data.Message;
        }         
      } catch (error) {
        throw(prettyResponse(error,(message)=>{
          switch(message){
            case "[passwordTooShort]":
              return "register.passwdShortErr";
            case "[invalidEmail]":
              return "register.invalidEmailErr";
            case "[userAlreadyExists]":
              return "register.emailInUseErr";
            case "Failed to create user!":
              return "register.emailInUseErr";
              default:
                return message;
          }
        }));
      }

    },

    // Register with full user data
    async register2(email: string, password: string, user: UserDetailedViewModel){
      const verifyEmailUrl = `${location.protocol}//${location.host}/verifyEmail`;
      try {
        const r = await postNow(`Users/register`, { 
          username:email, 
          email:email, 
          password:password, 
          verifyEmailUrl, 
          phone: ""
        }) as AxiosResponse<LoginResponse>;
        
        if(r.status === 200&& r.data.id && r.data.username && r.data.organizations && r.data.jwtToken){
          const token = jwtDecode(r.data.jwtToken) as any;
          this.username = r.data.username;
          this.id = r.data.id;
          this.organizations = r.data.organizations;
          this.authToken = r.data.jwtToken;
          this.roles = r.data.roles ?? [];
          this.issued = token.iat;
          this.expires = token.exp;

          const store = useStore();

          // Save user data to server
          const u = await store.dispatch(ActionTypes.UPDATE_FULL_PROFILE, user);
          await store.commit(MutationTypes.UPDATE_FULL_PROFILE, u);
          await store.dispatch(ActionTypes.REFRESH_CART, undefined);

          return;
        } else { 
          throw (r as any).data.Message;
        }         
      } catch (error) {
        throw(prettyResponse(error,(message)=>{
          switch(message){
            case "[passwordTooShort]":
              return "register.passwdShortErr";
            case "[invalidEmail]":
              return "register.invalidEmailErr";
            case "[userAlreadyExists]":
              return "register.emailInUseErr";
            case "Failed to create user!":
              return "register.emailInUseErr";
              default:
                return message;
          }
        }));
      }

    }

  }
  // other options...
})