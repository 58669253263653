
import { IonPage, IonText, loadingController, } from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { saveOutline } from "ionicons/icons";
import { useErrorBox } from "@/components/errorBox";
import { useTicketStore } from "@/store/ticketStore";
import { TicketInfo, TicketViewModel } from "@/models/Ticket";
import CardEdit from "@/components/CardEdit.vue";

export default defineComponent({
  name: "TicketDetailsPage",
  components: {
    IonPage,
    CardEdit,
    IonText,
  },
  setup() {
    const router = useRouter();
    const ticketStore = useTicketStore();

    const {showError} = useErrorBox();
    const ticket: Ref<TicketViewModel|undefined> = ref(undefined);

    const loading = async () => {
      const loading = await loadingController.create({ message: 'Hetki...', duration: undefined, });
      await loading.present();
      return loading;
    }    


    onMounted(async () => {
      const id = router.currentRoute.value.params["id"].toString();
      const l = await loading();
      try {
        ticket.value = await ticketStore.getTicketDetails(id);
      } catch(err){
        showError(err, "virhe");
      } finally{
        await l.dismiss();
      }
    });
    
    const saveTicket = async (ticket: Ref<TicketInfo>) => {
      alert("Ei vielä toteutettu!");
    }

    return {
      saveTicket,ticket,router,
      saveOutline,
    };
  }
});
