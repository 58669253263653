import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_CardEdit = _resolveComponent("CardEdit")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      (_ctx.ticket)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (!_ctx.ticket?.familyMember)
              ? (_openBlock(), _createBlock(_component_ion_text, {
                  key: 0,
                  color: "danger"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Huom! Kortilta puuttuu omistaja!")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_CardEdit, {
              ticket: _ctx.ticket,
              onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.router.back())),
              onUpdateTicket: _ctx.saveTicket
            }, null, 8, ["ticket", "onUpdateTicket"])
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}