import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home.vue'

import Customers from '@/views/customers/Customers.vue';
import Customer from '@/views/customers/Customer.vue';

import CheckIn from '@/views/CheckIn.vue';
import RegisterTo from '@/views/RegisterTo.vue';
import CheckOut from '@/views/CheckOut.vue';
import CheckOut2 from '@/views/CheckOut2.vue';
import CheckOut3 from '@/views/CheckOut3.vue';
import Purchase from '@/views/Purchase.vue';
import ListEvents from '@/views/ListEvents.vue';
import CalendarView from '@/views/calendar/Calendar.vue';

import Login from '@/views/auth/Login.vue';
import Register from '@/views/auth/Register.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import ResetWithToken from '@/views/auth/ResetWithToken.vue';

import CashRegister from '@/views/financial/CashRegister.vue';
import ShoppingCartsPage from '@/views/financial/ShoppingCartsPage.vue';
import SalesOfDay from '@/views/financial/SalesOfDay.vue';
import ShoppingCartDetailsPage from '@/views/financial/ShoppingCartsDetails.vue';
import DailySalesFix from '@/views/financial/DailySalesFix.vue';

import EditProduct from '@/views/EditProduct.vue';
import ListProducts from '@/views/ListProducts.vue';

import ListSqlRequests from '@/views/admin/ListSqlRequests.vue';
import DoorActionsPage from '@/views/admin/DoorActionsPage.vue';

import CheckOutPaymentOk from '@/views/CheckOutPaymentOk.vue';
import Partisipants from '@/views/Partisipants.vue';
import Reserve from '@/views/Reserve.vue';
import Profile from '@/views/Profile.vue';
import MainPage from '@/views/MainPage.vue';
import CardRead from '@/views/CardRead.vue';
import ListUserCategories from '@/views/ListUserCategories.vue';
import EditUserCategory from '@/views/EditUserCategory.vue';
import TraniningPrograms from '@/views/TraniningPrograms.vue';

import ListInfoPages from '@/views/infoPages/ListInfoPages.vue';
import EditInfoPage from '@/views/infoPages/EditInfoPage.vue';

import ListMessagePage from '@/views/messages/ListMessages.vue';
import ShowMessagePage from '@/views/messages/ShowMessage.vue';
import WriteMessagePage from '@/views/messages/WriteMessage.vue';

import TicketTypesListPage from '@/views/admin/TicketTypesListPage.vue';
import TicketTypeDetailsPage from '@/views/admin/TicketTypeDetailsPage.vue';
import TicketDetailsPage from '@/views/admin/TicketDetails.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/main/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/cardRead',
    name: 'CardRead',
    component: CardRead
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/resetWithToken',
    name: 'ResetWithToken',
    component: ResetWithToken
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/customer/:id',
    name: 'Customer',
    component: Customer
  },
  {
    path: '/checkin/:id/:cardreader?',
    name: 'CheckIn',
    component: CheckIn
  },
  {
    path: '/registerTo/:id',
    name: 'RegisterTo',
    component: RegisterTo
  },
  {
    path: '/checkout',
    name: 'CheckOut',
    component: CheckOut,
    props: { back: false }
  },
  {
    path: '/checkout1',
    name: 'CheckOutBack',
    component: CheckOut,
    props: { back: true }
  },
  {
    path: '/checkout2',
    name: 'CheckOut2',
    component: CheckOut2
  },
  {
    path: '/checkout3',
    name: 'CheckOut3',
    component: CheckOut3
  },
  {
    path: '/checkoutok',
    name: 'CheckOutPaymentOk',
    component: CheckOutPaymentOk
  },
  {
    path: '/listevents/:id?',
    name: 'ListEvents',
    component: ListEvents
  },
  {
    path: '/calendarpage/:id?',
    name: 'Calendar',
    component: CalendarView
  },
  {
    path: '/cashRegister/:id?',
    name: 'CashRegister',
    component: CashRegister
  },
  {
    path: '/editProduct/:id?',
    name: 'EditProduct',
    component: EditProduct
  },
  {
    path: '/editUserCategory/:id?',
    name: 'EditUserCategory',
    component: EditUserCategory
  },
  {
    path: '/editInfoPage/:id?',
    name: 'EditInfoPage',
    component: EditInfoPage
  },
  {
    path: '/purchase/:id?',
    name: 'Purchase',
    component: Purchase
  },
  {
    path: '/partisipants/:id?',
    name: 'Partisipants',
    component: Partisipants
  },
  {
    path: '/messages',
    name: 'ListMessages',
    component: ListMessagePage
  },
  {
    path: '/message/:id',
    name: 'ShowMessagePage',
    component: ShowMessagePage
  },
  {
    path: '/writemessage/:to?',
    name: 'WriteMessagePage',
    component: WriteMessagePage
  },
  {
    path: '/listProducts',
    name: 'ListProducts',
    component: ListProducts
  },
  {
    path: '/listInfoPages',
    name: 'ListInfoPages',
    component: ListInfoPages
  },
  {
    path: '/trainingPrograms',
    name: 'TraniningPrograms',
    component: TraniningPrograms
  },
  {
    path: '/listSqlRequests',
    name: 'ListSqlRequests',
    component: ListSqlRequests
  },
  {
    path: '/listUserCategories',
    name: 'ListUserCategories',
    component: ListUserCategories
  },
  {
    path: '/shoppingCartsPage',
    name: 'ShoppingCartsPage',
    component: ShoppingCartsPage
  },
  {
    path: '/dailySalesFix/:date?',
    name: 'DailySalesFix',
    component: DailySalesFix
  },
  {
    path: '/shoppingCart/:id?',
    name: 'ShoppingCartDetails',
    component: ShoppingCartDetailsPage
  },
  {
    path:'/salesOfDay',
    name:'SalesOfDay',
    component: SalesOfDay
  },
  {
    path:'/doorActions',
    name:'DoorActions',
    component: DoorActionsPage
  },
  {
    path:'/admin/ticketTypes',
    name:'TicketTypesList',
    component: TicketTypesListPage
  },  
  {
    path:'/admin/ticketTypes/:id',
    name:'TicketTypeDetails',
    component: TicketTypeDetailsPage
  },
  {
    path:'/admin/ticket/:id',
    name:'TicketDetails',
    component: TicketDetailsPage
  },
  {
    path:'/reserve/:id?',
    name:'Reserve',
    component: Reserve
  },
  {
    path:'/main',
    name:'MainPage',
    component: MainPage,
    children: [
      {
        path: '',
        redirect: '/main/home'
      },
      {
        path: 'home',
        component: Home
      },
      {
        path: 'calendar/:id?',
        component: CalendarView
      },
      {
        path: 'checkin/:id',
        component: CheckIn
      },
      {
        path: 'profile',
        component: Profile
      }, 
      {
        path:'trainingPrograms',
        component: TraniningPrograms
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes  
})

export default router
