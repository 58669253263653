import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9ad53dc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-title" }
const _hoisted_2 = { class: "auth-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    "aria-label": "locale",
                    modelValue: _ctx.locale,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.locale) = $event)),
                    interface: "popover",
                    onIonChange: _ctx.localeChanged
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select_option, {
                        key: "fi-FI",
                        value: "fi-FI"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Suomeksi")
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_select_option, {
                        key: "en-US",
                        value: "en-US"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("English")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue", "onIonChange"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("login.title")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "login-content" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { class: "content-grid" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, { color: "primary" }, {
                        default: _withCtx(() => [
                          _createElementVNode("h1", _hoisted_1, _toDisplayString(_ctx.t("login.title")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_text, { color: "primary" }, {
                        default: _withCtx(() => [
                          _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.t("login.message")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_list, { class: "inputs-list" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, { class: "input-item" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                id: "username",
                                placeholder: _ctx.t('login.username'),
                                modelValue: _ctx.loginInfo.username,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.loginInfo.username) = $event))
                              }, null, 8, ["placeholder", "modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, { class: "input-item" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                id: "password",
                                placeholder: _ctx.t('login.password'),
                                type: "password",
                                modelValue: _ctx.loginInfo.password,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.loginInfo.password) = $event)),
                                onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.loginNow(_ctx.loginInfo)), ["enter"]))
                              }, null, 8, ["placeholder", "modelValue"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_button, {
                        color: "primary",
                        class: "login-btn",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loginNow(_ctx.loginInfo))),
                        expand: "block"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.logInOutline }, null, 8, ["icon"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.t("login.signinBtn")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_button, {
                        fill: "clear",
                        color: "secondary",
                        class: "login-btn",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.router.replace('/resetPassword'))),
                        expand: "block"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.syncOutline }, null, 8, ["icon"]),
                          _createTextVNode(" " + _toDisplayString(_ctx.t("login.changePaswordBtn")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_fab, {
            vertical: "bottom",
            horizontal: "start",
            slot: "fixed"
          }, {
            default: _withCtx(() => [
              (_ctx.cart?.rows?.length>0)
                ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                    key: 0,
                    color: "warning",
                    onClick: _cache[6] || (_cache[6] = () => _ctx.router.replace('/checkout'))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.cartOutline }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}