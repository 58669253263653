import { ProductViewModel } from "./ProductViewModel";
import { UserPaymentViewModel, UserProfileViewModel } from "./UserDetailedViewModel";
import { RequestResponse } from "./UserSearchModel";
import router from '@/router'
import { roundToCent } from "@/common/utils";
import { TicketViewModel } from "./Ticket";


export class FinAccountModel {
    id: string; 
    name: string; 
    constructor(){
        this.id = "";
        this.name = "";
    }
}

export class ShoppingCartRow {
    id: string|undefined; 
    eventId: string|undefined; 
    ticketId: string|undefined; 
    ticketTypeId: string|undefined; 
    price: number|undefined; 
    quantity: number; 
    comments: string|undefined; 
    discount: number|undefined; 
    name: string|undefined;
    memberId: string|undefined;
    constructor(){
        this.quantity = 0;
    }
  }


export class ShoppingCartRequest
{
    successUrl: string;
    cancelUrl: string;
    id: string|undefined;
    state: string|undefined;
    number: number|undefined;
    type: string|undefined;
    organizationId: string|undefined;
    rows: Array<ShoppingCartRow>;
    agreed: Date|undefined;
    constructor(){
        this.successUrl = "";
        this.cancelUrl = "";
        this.rows = [];
    }
}

export class ShoppingCartResponse
{
    id: string|undefined;
    state: string;
    number: number | undefined;
    type: string;
    organizationId: string|undefined;
    rows: Array<ShoppingCartRow>;
    agreed: Date|undefined;
    constructor(){
        this.state = "";
        this.type = "";
        this.rows = [];        
    }


}
  export class ShoppingCart { 
    id: string|undefined; 
    state: string|undefined; 
    number: number|undefined; 
    type: string|undefined;
    organizationId: string|undefined;
    rows: Array<ShoppingCartRow>; 
    agreed: Date|undefined;
    constructor(){
        this.rows = [];
    }

    toShoppingCartRequest= (from: ShoppingCart) => {
        const cart = new ShoppingCartRequest();
        cart.id = from.id;
        cart.state = from.state;
        cart.number = from.number;
        cart.type = from.type;
        cart.rows = from.rows;
        cart.agreed = from.agreed;
        cart.organizationId = from.organizationId;
        const host = `${location.protocol}//${location.host}`;
        cart.successUrl = host + router.resolve({name:'CheckOutPaymentOk'}).href;
        cart.cancelUrl = host + router.resolve({name:'CheckOutPaymentOk'}).href;
        return cart;
    }

    fromResult = (cart: ShoppingCartResponse) => {
        this.id = cart.id;
        this.state = cart.state;
        this.number = cart.number;
        this.type = cart.type;
        this.rows = cart.rows;
        this.agreed = cart.agreed;
        this.organizationId = cart.organizationId;
    }
}

export class ShoppingCartLineViewModel 
{
    id: string | undefined;
    price: number | undefined;
    quantity: number | undefined;
    discount: number | undefined;
    index: number | undefined;
    vatPersentage: number | undefined;
    total: number | undefined;

    //Readonly fields
    name: string | undefined;
    defaultPrice: number | undefined;

    product: ProductViewModel | undefined;
    ticket: TicketViewModel | undefined;
}

export class ShoppingCartViewModel 
{
    id: string | undefined;
    state: string | undefined;
    number: number | undefined;
    type: string | undefined;
    organizationId: string|undefined;

    comments: string | undefined;
    reference1: string | undefined;
    reference2: string | undefined;

    created: Date | undefined;
    dueDate: Date | undefined;
    agreed: Date | undefined; 

    bankReference: string | undefined;

    client: string | undefined;
    printer: string | undefined;

    userPayment: Array<UserPaymentViewModel> | undefined;
    shoppingCartLines: Array<ShoppingCartLineViewModel> | undefined;

    deliveryCost: number | undefined;

    //Readonly
    total: number | undefined;
    totalWithDelivery: number | undefined;
    totalUnpaid = ()=>{

        let sum = 0;
        this.shoppingCartLines?.forEach(i=>sum = sum + roundToCent(((i.quantity??1)*((i.price ?? 0)-(i.discount??0)))));
        this.userPayment?.forEach(i=>sum = sum - roundToCent(i.fixedAmount));
        if(Math.abs(sum) < 0.01)
          return 0;
        return sum;

    };

    vatInfos: Array<any> | undefined;

    userProfile: UserProfileViewModel | undefined;
}


export class NetvisorStatus
{
    updateTime: Date|undefined;
    state: string|undefined;
    comments: string|undefined;
}

export class EditShoppingCartViewModel extends ShoppingCartViewModel{

    netvisorStatus: NetvisorStatus|undefined;
}

export class EditShoppingCartMessage extends RequestResponse<EditShoppingCartViewModel> 
{

}


export class MoneyAccountViewModel2
{
    id: string;
    name: string;
    icon: string;
    type: string;
    canBeReturned: boolean;
    orderNumber: number;
    roundingSize: number;

    constructor() {
        this.id = "";
        this.name = "";
        this.icon = "";
        this.type = "";
        this.canBeReturned = false;
        this.orderNumber = 0;
        this.roundingSize = 1;
    }
}






export class ReturnedStatusUpdateRequest 
{ 
    id: string;
    parameters: object;
    constructor() {
        this.id ="";
        //this.parameters = {};

        const parameters = router.currentRoute.value.query

        console.log(parameters);
        this.parameters = parameters
    }
}