import { StorageSerializers, useLocalStorage } from "@vueuse/core";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { getNow, postNow, prettyResponse, useStore } from ".";
import { EditShoppingCartMessage, EditShoppingCartViewModel, FinAccountModel, MoneyAccountViewModel2, ShoppingCartViewModel } from "@/models/ShoppingCart";
import { ShoppingCartIndex2 } from "@/models/ShoppingCartIndex";
import { PaymentDailyCheckModel, SalesOfDayModel } from "@/models/SalesOfTheDay";
import { ProductListReponse, VatGroupViewModel } from "@/models/ProductViewModel";
import { PaymentSelectModel } from "@/models/PaymentModels";
import { ListResponse } from "@/models/UserSearchModel";
import { DiscountDto, DiscountGroupDto } from "@/models/Discount";



export const useFinancialStore = defineStore('financial', {
    state: () => ({
      test: useLocalStorage("test", "",{ serializer: StorageSerializers.object,  mergeDefaults: true  }),
    }),
  
    getters: {
      getAccounts: () => {
        return [{
        id:"3413c9da-86f3-4ba3-9a1c-5a921c622043", 
        name:"käteinen",
        canBeReturned:true,
        icon:"",
        orderNumber:5,
        type:"kassa",
        roundingSize: 0.05
        },{
        id:"13a487c3-6e7a-452d-8c8b-e69e1d5fbb1e", 
        name:"pankkikortti",
        canBeReturned:false,
        icon:"",
        orderNumber:3,
        type:"kassa",
        roundingSize: 0.01
        },{
        id:"30fe7448-b59c-4d5b-ae70-c3d6cdd68768", 
        name:"liikuntasetelit",
        canBeReturned:false,
        icon:"",
        orderNumber:2,
        type:"kassa",
        roundingSize: 0.01
        }] as  Array<MoneyAccountViewModel2>;
      },
    }, 
  
    actions: {
      async getShoppingCart(organizationId: string) {
        try {
            const params = new URLSearchParams([['id', organizationId]]);
            const r = await getNow(`shoppingcart/get`, params) as AxiosResponse<EditShoppingCartMessage>;

            if(r.data.success===true) {
                return r.data.item;
              } else {
                throw new Error((r as any).data.Message);
              }
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async removeShoppingCart(id: string, force?: boolean) {
        const params = new URLSearchParams([ ['id', id]]);
        if(force) params.append("force", "true");

        try {
          await getNow(`shoppingcart/remove`, params);
          return;
        }
        catch (reason) {
          throw (prettyResponse(reason,(message)=>{
            switch(message){
              case "Status error":
                return "Tässä tilassa olevaa ostosta ei voi poistaa.";
                default:
                  return message;
            }}));
          }
      },

      async removePayment(id: string, force?: boolean) {
        const params = new URLSearchParams([ ['id', id]]);
        if(force) params.append("force", "true");

        try {
          await getNow(`shoppingcart/removePayment`, params);
          return;
        }
        catch (reason) {
          throw (prettyResponse(reason,(message)=>{
            switch(message){
              case "Status error":
                return "Tässä tilassa olevaa ostosta ei voi poistaa.";
                default:
                  return message;
            }}));
          }
      },

      async removeShoppingCartLine(id: string, force?: boolean) {
        const params = new URLSearchParams([ ['id', id]]);
        if(force) params.append("force", "true");

        try {
          await getNow(`shoppingcart/removeLine`, params);
          return;
        }
        catch (reason) {
          throw (prettyResponse(reason,(message)=>{
            switch(message){
              case "Status error":
                return "Tässä tilassa olevaa ostosta ei voi poistaa.";
                default:
                  return message;
            }}));
          }
      },
      
      async getVatGroups() {
        try {
            const r = await getNow(`VatGroup/List`, undefined) as AxiosResponse<Array<VatGroupViewModel>>;
            return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async getShoppingCartsOfDay(type: string, from: Date, to: Date) {
        const store = useStore();
        try {
            const params = new URLSearchParams([ ['type', type],
            ['from', from.toJSON()],
            ['to', to.toJSON()],
            ['organizationId', store.state.organization.id]]);

            const r = await getNow(`shoppingcart/DetailsList`, params) as AxiosResponse<Array<ShoppingCartIndex2>>;

            const list = new Array<ShoppingCartIndex2>();         
            r.data.forEach(i=>{
              const r = (new ShoppingCartIndex2).fromResponse(i);
              list.push(r);
            },this);
            return list;

        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async getSalesOfDays(source:string, from: Date, to: Date) {
        const store = useStore();
        try {          
        const params = new URLSearchParams([ ['source', source],
          ['dateFrom', from.toJSON()],
          ['dateTo', to.toJSON()],
          ['organizationId', store.state.organization.id]]);

          const r = await getNow(`shoppingcart/SalesOfDayJson`, params) as AxiosResponse<Array<SalesOfDayModel>>;
          return r.data;        

        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async getPaymentOptions() {
        try {
          const response = await getNow(`shoppingcart/PaymentOptions`, undefined) as AxiosResponse<Array<PaymentSelectModel>>;
          if (response.data.length > 0) {
            return response.data;
          } else {
            throw new Error("Not found");
          }
        } catch (reason) {
          throw (prettyResponse(reason, undefined));
        }
      },

      async getFinAccounts() {
        try {
          const response = await getNow(`FinAccounts`, undefined) as AxiosResponse<ListResponse<FinAccountModel>>;
          if (response.data.success === true) {
            return response.data.items;
          } else {
            throw new Error(response.data.message);
          }
        } catch (reason) {
          throw (prettyResponse(reason, undefined));
        }
      },

      async getDiscounts() {
        const params = new URLSearchParams([['organizationId', useStore().state.organization.id ?? ""]]);
        const response = await getNow(`Discount`, params) as AxiosResponse<Array<DiscountDto>>;
        return response.data;
      },
      
      async getDiscountGroups() {
        const params = new URLSearchParams([['organizationId', useStore().state.organization.id ?? ""]]);
        const response = await getNow(`Discount/Groups`, params) as AxiosResponse<Array<DiscountGroupDto>>;
        return response.data;
      },

      async getProducts() {
        const params = new URLSearchParams([['organizationId', useStore().state.organization.id ?? ""]]);

        try {
          const response = await getNow(`Product`, params) as AxiosResponse<ProductListReponse>;
          if (response.data.success === true) {
            return response.data;
          } else {
            throw new Error(response.data.message);
          }
        } catch (reason) {
          throw (prettyResponse(reason, undefined));
        }
      },

      async printReceipt(cartId: string) {
        const store = useStore();
        try {
            const params = new URLSearchParams([['cartId', cartId], ['organizationId', store.state.organization.id]]);
            await getNow(`shoppingcart/PrintReceipt`, params);
            return;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async emailReceipt(cartId: string, email: string) {
        try {
            const params = new URLSearchParams([['cartId', cartId], ['email', email]]);
            await getNow(`shoppingcart/EmailReceipt`, params);
            return;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async getPaymentDailyCheck(source: string, from: Date) {
        const store = useStore();
        try {
          const params = new URLSearchParams([ ['source', source],
          ['date', from.toJSON()],
          ['organizationId', store.state.organization.id]]);

          const r = await getNow(`shoppingcart/PaymentDailyCheckJson`, params) as AxiosResponse<PaymentDailyCheckModel>;
          return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async updatePaymentDailyCheck(payload: PaymentDailyCheckModel) {
        try {          
          const r = await postNow(`shoppingcart/PaymentDailyCheckJson`, payload) as AxiosResponse<PaymentDailyCheckModel>;
          return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

      async saveShoppingCart(payload: ShoppingCartViewModel) {
        try {
          if(!payload)
            throw new Error("No payload");

          const r = await postNow(`shoppingcart/post`, payload) as AxiosResponse<EditShoppingCartViewModel>;

          if(!r.data.id)
            throw new Error("Error in response data");

          return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },

    }
  })