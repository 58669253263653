import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_LocationItem = _resolveComponent("LocationItem")!
  const _component_InstructorItem = _resolveComponent("InstructorItem")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { menu: "main-menu" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.organization.name) + " kalenteri", 1)
                ]),
                _: 1
              }),
              (_ctx.multiMembers && _ctx.familyMember)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    fill: "clear",
                    color: "primary",
                    onClick: _ctx.nextFamilyMember,
                    slot: "end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.repeatOutline }, null, 8, ["icon"]),
                      _createVNode(_component_ion_text, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.familyMember?.firstName), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_select, {
                "aria-label": "Tunnit",
                placeholder: "Näkyvät tunnit",
                multiple: true,
                modelValue: _ctx.calendarCollections,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.calendarCollections) = $event)),
                "cancel-text": "peru",
                "ok-text": "päivitä"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.collections, (collection) => {
                    return (_openBlock(), _createBlock(_component_ion_select_option, {
                      key: collection.id,
                      value: collection.id
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(collection.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["value"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_ctx.familyMember)
                ? (_openBlock(), _createBlock(_component_ion_datetime_button, {
                    key: 0,
                    fill: "clear",
                    color: "primary",
                    datetime: "datetime",
                    slot: "end"
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_datetime, {
                    id: "datetime",
                    presentation: "date",
                    "first-day-of-week": 1,
                    value: _ctx.momentjs(_ctx.date).startOf('day').add(4, 'hours').toISOString(),
                    onIonChange: _ctx.selectDate
                  }, null, 8, ["value", "onIonChange"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[1] || (_cache[1] = ($event: any) => (_ctx.refreshNow($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }),
          (_ctx.processing)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode("ladataan")
              ], 64))
            : _createCommentVNode("", true),
          (!_ctx.processing && _ctx.calendar?.days)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.calendar.days, (day) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "day-container",
                    key: day.date.toISOString()
                  }, [
                    _createVNode(_component_ion_item_sliding, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_text, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h2", null, _toDisplayString(day.name) + " " + _toDisplayString(_ctx.momentjs(day.date).format("DD.MM.")), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_ion_item_options, { side: "end" }, {
                          default: _withCtx(() => [
                            (!_ctx.showAll)
                              ? (_openBlock(), _createBlock(_component_ion_item_option, {
                                  key: 0,
                                  color: "primary",
                                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAll = !_ctx.showAll))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" näytä kaikki ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true),
                            (_ctx.showAll)
                              ? (_openBlock(), _createBlock(_component_ion_item_option, {
                                  key: 1,
                                  color: "light",
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showAll = !_ctx.showAll))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" piilota ")
                                  ]),
                                  _: 1
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1024),
                    (day.events && day.events.length > 0)
                      ? (_openBlock(), _createBlock(_component_ion_list, {
                          key: 0,
                          style: {"padding-top":"0","padding-bottom":"0"}
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(day.events, (eventInstance) => {
                              return (_openBlock(), _createBlock(_component_ion_item_sliding, {
                                key: eventInstance.instanceID
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_item_options, { side: "start" }, {
                                    default: _withCtx(() => [
                                      (!_ctx.isFavorite(eventInstance))
                                        ? (_openBlock(), _createBlock(_component_ion_item_option, {
                                            key: 0,
                                            onClick: ($event: any) => (_ctx.favorite(eventInstance))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                slot: "icon-only",
                                                icon: _ctx.starOutline
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                        : _createCommentVNode("", true),
                                      (_ctx.isFavorite(eventInstance))
                                        ? (_openBlock(), _createBlock(_component_ion_item_option, {
                                            key: 1,
                                            onClick: ($event: any) => (_ctx.favorite(eventInstance))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                slot: "icon-only",
                                                icon: _ctx.star
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  (!_ctx.isHidden(eventInstance) || _ctx.showAll)
                                    ? (_openBlock(), _createBlock(_component_ion_item, {
                                        key: 0,
                                        class: _normalizeClass(["event-instance col-xs", [eventInstance.class ?? '', _ctx.isFavorite(eventInstance) ? 'favorite' : '']])
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_row, { style: {"width":"100%"} }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_col, {
                                                "size-xs": "3",
                                                "size-sm": "2",
                                                "size-md": "2",
                                                "size-lg": "2",
                                                "size-xl": "1"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_row, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_col, {
                                                        size: "8",
                                                        class: "headRow",
                                                        style: {"white-space":"nowrap"}
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(new Date(eventInstance.beginTime).getHours()) + ":" + _toDisplayString(String(new Date(eventInstance.beginTime).getMinutes()).padStart(2, "0")), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_ion_row, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_col, null, {
                                                        default: _withCtx(() => [
                                                          (_ctx.timeMinutes(eventInstance) <= 60)
                                                            ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(_ctx.timeMinutes(eventInstance)) + " min", 1)
                                                                ]),
                                                                _: 2
                                                              }, 1024))
                                                            : _createCommentVNode("", true),
                                                          (_ctx.timeMinutes(eventInstance) > 60)
                                                            ? (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(" - " + _toDisplayString(new Date(eventInstance.endTime).getHours()) + ":" + _toDisplayString(String(new Date(eventInstance.endTime).getMinutes()).padStart(2,"0")), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1024))
                                                            : _createCommentVNode("", true)
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1024)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_col, {
                                                "size-xs": "5",
                                                "size-sm": "6",
                                                "size-md": "6",
                                                "size-lg": "8",
                                                "size-xl": "6"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_row, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_col, { class: "headRow" }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(eventInstance.title) + " ", 1),
                                                          (eventInstance.comments)
                                                            ? (_openBlock(), _createBlock(_component_ion_text, {
                                                                key: 0,
                                                                color: "danger"
                                                              }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString(eventInstance.comments), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1024))
                                                            : _createCommentVNode("", true)
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  _createVNode(_component_ion_row, null, {
                                                    default: _withCtx(() => [
                                                      _createVNode(_component_ion_col, { class: "secondRow" }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_LocationItem, {
                                                            id: eventInstance.locationId
                                                          }, null, 8, ["id"])
                                                        ]),
                                                        _: 2
                                                      }, 1024),
                                                      _createVNode(_component_ion_col, { class: "secondRow" }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_InstructorItem, {
                                                            data: eventInstance.instructors
                                                          }, null, 8, ["data"])
                                                        ]),
                                                        _: 2
                                                      }, 1024)
                                                    ]),
                                                    _: 2
                                                  }, 1024),
                                                  (_ctx.canReserve(eventInstance))
                                                    ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_ion_col, { class: "secondRow" }, {
                                                            default: _withCtx(() => [
                                                              _createVNode(_component_ion_text, { color: "secondary" }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode(_toDisplayString((eventInstance.partisipants ?? 0) - (eventInstance.reservations ?? 0)) + " paikkaa", 1)
                                                                ]),
                                                                _: 2
                                                              }, 1024)
                                                            ]),
                                                            _: 2
                                                          }, 1024)
                                                        ]),
                                                        _: 2
                                                      }, 1024))
                                                    : _createCommentVNode("", true)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_col, {
                                                "size-xs": "4",
                                                "size-sm": "4",
                                                "size-md": "4",
                                                "size-lg": "2",
                                                "size-xl": "2"
                                              }, {
                                                default: _withCtx(() => [
                                                  (eventInstance.reservationRequired && _ctx.canReserveMore(eventInstance) &&
                            _ctx.canReserve(eventInstance) && !_ctx.processing2)
                                                    ? (_openBlock(), _createBlock(_component_ion_button, {
                                                        key: 0,
                                                        fill: "clear",
                                                        expand: "block",
                                                        color: "success",
                                                        size: "md",
                                                        onClick: ($event: any) => (_ctx.registerToEvent(eventInstance))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          ((eventInstance.reserveSlots??1) < 2)
                                                            ? (_openBlock(), _createBlock(_component_ion_text, { key: 0 }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode("varaa")
                                                                ]),
                                                                _: 1
                                                              }))
                                                            : _createCommentVNode("", true),
                                                          ((eventInstance.reserveSlots??1) > 1)
                                                            ? (_openBlock(), _createBlock(_component_ion_text, { key: 1 }, {
                                                                default: _withCtx(() => [
                                                                  _createTextVNode("varaa " + _toDisplayString(_ctx.isRegistered(eventInstance)) + " / " + _toDisplayString(eventInstance.reserveSlots ?? 1), 1)
                                                                ]),
                                                                _: 2
                                                              }, 1024))
                                                            : _createCommentVNode("", true)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["onClick"]))
                                                    : _createCommentVNode("", true),
                                                  (eventInstance.reservationRequired && _ctx.isRegistered(eventInstance) &&
                            _ctx.canReserve(eventInstance) && !_ctx.processing2)
                                                    ? (_openBlock(), _createBlock(_component_ion_button, {
                                                        key: 1,
                                                        fill: "clear",
                                                        expand: "block",
                                                        color: "warning",
                                                        size: "md",
                                                        onClick: ($event: any) => (_ctx.unRegisterEvent(eventInstance))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode("peru " + _toDisplayString(_ctx.isRegistered(eventInstance)), 1)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["onClick"]))
                                                    : _createCommentVNode("", true),
                                                  (_ctx.hasRoleInstructor)
                                                    ? (_openBlock(), _createBlock(_component_ion_button, {
                                                        key: 2,
                                                        fill: "clear",
                                                        expand: "block",
                                                        onClick: ($event: any) => (_ctx.showPartisipants(eventInstance))
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createVNode(_component_ion_icon, { icon: _ctx.peopleOutline }, null, 8, ["icon"]),
                                                          _createTextVNode("  "),
                                                          _createVNode(_component_ion_text, null, {
                                                            default: _withCtx(() => [
                                                              _createTextVNode(_toDisplayString(eventInstance.reservations) + " / " + _toDisplayString(eventInstance.visits) + " / " + _toDisplayString(eventInstance.partisipants), 1)
                                                            ]),
                                                            _: 2
                                                          }, 1024)
                                                        ]),
                                                        _: 2
                                                      }, 1032, ["onClick"]))
                                                    : _createCommentVNode("", true)
                                                ]),
                                                _: 2
                                              }, 1024)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]),
                                        _: 2
                                      }, 1032, ["class"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_ion_item_options, { side: "end" }, {
                                    default: _withCtx(() => [
                                      (!_ctx.isHidden(eventInstance))
                                        ? (_openBlock(), _createBlock(_component_ion_item_option, {
                                            key: 0,
                                            color: "danger",
                                            onClick: ($event: any) => (_ctx.hide(eventInstance))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                slot: "icon-only",
                                                icon: _ctx.trash
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                        : _createCommentVNode("", true),
                                      (_ctx.isHidden(eventInstance))
                                        ? (_openBlock(), _createBlock(_component_ion_item_option, {
                                            key: 1,
                                            color: "primary",
                                            onClick: ($event: any) => (_ctx.hide(eventInstance))
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                slot: "icon-only",
                                                icon: _ctx.trash
                                              }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024))
                            }), 128))
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true)
                  ]))
                }), 128)),
                _createVNode(_component_ion_infinite_scroll, {
                  onIonInfinite: _cache[4] || (_cache[4] = ($event: any) => (_ctx.loadData($event))),
                  threshold: "100px",
                  id: "infinite-scroll"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_infinite_scroll_content, {
                      "loading-spinner": "bubbles",
                      "loading-text": "ladataan..."
                    })
                  ]),
                  _: 1
                })
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}