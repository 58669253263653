
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonMenuButton,
  IonTextarea,
  IonModal,
  IonDatetime,
  IonDatetimeButton,
  loadingController,
  IonInput,
  toastController,
  IonBackButton,
  IonIcon,
  IonButton,
} from "@ionic/vue";
import { computed, defineComponent, onMounted, ref, } from "vue";
import { calendarOutline, printOutline, sendOutline, saveOutline, } from 'ionicons/icons';
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { useErrorBox } from '@/components/errorBox';
import * as moment from 'moment-timezone';
import { useFinancialStore } from "@/store/financialStore";
import { EditShoppingCartViewModel } from "@/models/ShoppingCart";
import { UserPaymentViewModel } from "@/models/UserDetailedViewModel";
import { createGuid } from "@/common/utils";
import { useAuthStore } from "@/store/authStore";

export default defineComponent({
  name: "DailySalesFixPage",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonBackButton,
    IonIcon,
    IonButton,
    IonModal,
    IonTextarea,
    IonDatetime,
    IonDatetimeButton,
    IonMenuButton,
    IonInput,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const auth = useAuthStore();
    const financial = useFinancialStore();
    const {showError} = useErrorBox();
    const momentjs: any = moment;


    const date = ref(new Date());
    const amounts = ref<Array<UserPaymentViewModel>>([]);
    const comments = ref<string>("");

    const hasRoleAccounting = computed(() => auth.roles?.indexOf("Accounting") >= 0);

    const loading = async () => {
      const loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Hetki...',
            duration: undefined,
          });

      await loading.present();
      return loading;
    }
  
    onMounted(() => {
      if (!hasRoleAccounting.value) {
        showError("Ei oikeuksia", "virhe");
        router.replace("/shoppingCartsPage");
      }
       amounts.value = financial.getAccounts.map(i=>UserPaymentViewModel.fromAccount(i));
    
    });
   

    const selectDate = (message: CustomEvent) => {
      const d = momentjs(message.detail.value);
      date.value = d;
    };

    const save = async () => {
      const l = await loading();
      try {
        if(!amounts.value || amounts.value.length === 0)
          throw "Ei tilejä";

        if(amounts.value.filter(i=>i.amount).length === 0)
          throw "Ei summaa";

        const cart = new EditShoppingCartViewModel();
        cart.id = createGuid();
        cart.comments = comments.value;
        cart.created = date.value;
        cart.userPayment = amounts.value;
        cart.total = 0;
        cart.deliveryCost = 0;
        cart.shoppingCartLines = [];
        cart.organizationId = store.state.organization.id;
        amounts.value.forEach(i=>i.organizationId=store.state.organization.id);

        cart.state = "CLOSED";
        cart.type = "kassafix";

        await financial.saveShoppingCart(cart);
        const toast = await toastController.create({
          message: 'Korjaus tallennettu',
          duration: 2000
        });
        toast.present();
        router.replace("/shoppingCartsPage");
      } catch (err){
        showError(err as string, "virhe"); 
      } finally {
        l.dismiss();
      }
    };

    

    return {
      router,
      calendarOutline,printOutline,sendOutline,saveOutline,
      momentjs,
      amounts, date, loading, selectDate,
      hasRoleAccounting, save, comments,
    };
  },
});
