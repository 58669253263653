import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { defaultHref: "/financial/shoppingcarts" }),
                  _createVNode(_component_ion_menu_button, { menu: "main-menu" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Ostos " + _toDisplayString(_ctx.cart?.number), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  (_ctx.hasRoleAdmin)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.presentRemoveReceipt())),
                        color: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"]),
                          _createTextVNode(" Poista pysyvästi ")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.hasRoleInstructor && _ctx.cart)
        ? (_openBlock(), _createBlock(_component_ion_content, {
            key: 0,
            class: "ion-padding"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Tuoterivit")
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart.shoppingCartLines, (line) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: line.id
                }, {
                  default: _withCtx(() => [
                    (line.product)
                      ? (_openBlock(), _createBlock(_component_ion_col, {
                          key: 0,
                          size: "4"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", null, _toDisplayString(line.product.name), 1)
                          ]),
                          _: 2
                        }, 1024))
                      : _createCommentVNode("", true),
                    (line.ticket)
                      ? (_openBlock(), _createBlock(_component_ion_col, {
                          key: 1,
                          size: "4",
                          style: {"cursor":"pointer"},
                          onClick: ($event: any) => (_ctx.openTicketDetails(line.ticket.id))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("a", null, _toDisplayString(line.ticket.titleString), 1)
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_ion_input, {
                      label: "Hinta",
                      "label-placement": "stacked",
                      value: line.price?.toFixed(2)
                    }, null, 8, ["value"]),
                    _createVNode(_component_ion_input, {
                      label: "Kpl",
                      "label-placement": "stacked",
                      value: line.quantity
                    }, null, 8, ["value"]),
                    _createVNode(_component_ion_input, {
                      label: "Alennus",
                      "label-placement": "stacked",
                      value: line.discount?.toFixed(2)
                    }, null, 8, ["value"]),
                    _createVNode(_component_ion_input, {
                      label: "Yhteensä",
                      "label-placement": "stacked",
                      value: line.total?.toFixed(2)
                    }, null, 8, ["value"]),
                    (_ctx.hasRoleAdmin)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 2,
                          onClick: ($event: any) => (_ctx.presentRemoveLine(line)),
                          color: "danger"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"])
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_ion_title, { style: {"margin-top":"20px"} }, {
                default: _withCtx(() => [
                  _createTextVNode("Maksut")
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart.userPayment, (payment) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: payment.id
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_input, {
                      label: "Paikka",
                      "label-placement": "stacked",
                      value: payment.source + '/' + payment.account.name
                    }, null, 8, ["value"]),
                    _createVNode(_component_ion_input, {
                      label: "Maksettu",
                      "label-placement": "stacked",
                      value: payment.amount.toFixed(2)
                    }, null, 8, ["value"]),
                    _createVNode(_component_ion_input, {
                      label: "Palautettu",
                      "label-placement": "stacked",
                      value: payment.returned.toFixed(2)
                    }, null, 8, ["value"]),
                    _createVNode(_component_ion_input, {
                      label: "Vastaanotettu",
                      "label-placement": "stacked",
                      value: _ctx.momentjs(payment.received).format('DD.MM.yyyy H:mm:ss')
                    }, null, 8, ["value"]),
                    (_ctx.hasRoleAdmin)
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 0,
                          onClick: ($event: any) => (_ctx.presentRemovePayment(payment)),
                          color: "danger"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"])
                          ]),
                          _: 2
                        }, 1032, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_ion_title, { style: {"margin-top":"20px"} }, {
                default: _withCtx(() => [
                  _createTextVNode("Verot")
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cart.vatInfos, (vat) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: vat.persetage
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_input, {
                      label: "Prosentti",
                      "label-placement": "stacked",
                      value: vat.persentage
                    }, null, 8, ["value"]),
                    _createVNode(_component_ion_input, {
                      label: "Veroton",
                      "label-placement": "stacked",
                      value: vat.amount.toFixed(2)
                    }, null, 8, ["value"]),
                    _createVNode(_component_ion_input, {
                      label: "Vero",
                      "label-placement": "stacked",
                      value: vat.vat.toFixed(2)
                    }, null, 8, ["value"]),
                    _createVNode(_component_ion_input, {
                      label: "Yhteensä",
                      "label-placement": "stacked",
                      value: vat.total.toFixed(2)
                    }, null, 8, ["value"])
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_ion_input, {
                fill: "outline",
                modelValue: _ctx.email,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                type: "email",
                label: "Sähköposti"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}