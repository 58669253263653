import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_datetime_button = _resolveComponent("ion-datetime-button")!
  const _component_ion_datetime = _resolveComponent("ion-datetime")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/admin/tickettypes" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Kortti tyyppi")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  (_ctx.ticketType)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        disabled: !_ctx.isDirty,
                        color: "primary",
                        size: "default",
                        onClick: _ctx.save,
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, { icon: _ctx.saveOutline }, null, 8, ["icon"]),
                          _createVNode(_component_ion_text, null, {
                            default: _withCtx(() => [
                              _createTextVNode(" tallenna")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["disabled", "onClick"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.ticketType)
        ? (_openBlock(), _createBlock(_component_ion_content, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.ticketType.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ticketType.name) = $event)),
                    label: "Nimi",
                    labelPlacement: "stacked"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.ticketType.description,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.ticketType.description) = $event)),
                    label: "Kuvaus",
                    labelPlacement: "stacked"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.ticketType.categories,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.ticketType.categories) = $event)),
                    placeholder: "Valitse kategoriat",
                    multiple: true,
                    label: "Kortilla voi käyttää näiden kategorioiden tuonteja",
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoriesFiltered, (cat) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: cat.id,
                          value: cat.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(cat.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.ticketType.validityDays,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.ticketType.validityDays) = $event)),
                    type: "number",
                    step: "1",
                    inputmode: "numeric",
                    label: "Voimassa päivää",
                    "label-placement": "stacked"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.ticketType.validityTimes,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ticketType.validityTimes) = $event)),
                    type: "number",
                    step: "1",
                    inputmode: "numeric",
                    label: "Käyttökertoja",
                    "label-placement": "stacked"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Voimassaoloaika alkaa")
                    ]),
                    _: 1
                  }),
                  (_ctx.ticketType.validityBegins)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ticketType.validityBegins = undefined))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Poista")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.ticketType.validityBegins)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.ticketType.validityBegins = new Date()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Aseta")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.ticketType.validityBegins)
                    ? (_openBlock(), _createBlock(_component_ion_datetime_button, {
                        key: 2,
                        datetime: "validityBegins"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                default: _withCtx(() => [
                  (_ctx.ticketType.validityBegins)
                    ? (_openBlock(), _createBlock(_component_ion_datetime, {
                        key: 0,
                        id: "validityBegins",
                        presentation: "date",
                        locale: "fi-FI",
                        "prefer-wheel": false,
                        value: _ctx.getForIonDateTime(_ctx.ticketType.validityBegins),
                        onIonChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.ticketType.validityBegins = _ctx.setFromIonDateTime($event.detail.value))),
                        max: "2030"
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Voimassaoloaika loppuu")
                    ]),
                    _: 1
                  }),
                  (_ctx.ticketType.validityEnds)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.ticketType.validityEnds = undefined))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Poista")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.ticketType.validityEnds)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.ticketType.validityEnds = new Date()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Aseta")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.ticketType.validityEnds)
                    ? (_openBlock(), _createBlock(_component_ion_datetime_button, {
                        key: 2,
                        datetime: "validityEnds"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                default: _withCtx(() => [
                  (_ctx.ticketType.validityEnds)
                    ? (_openBlock(), _createBlock(_component_ion_datetime, {
                        key: 0,
                        id: "validityEnds",
                        presentation: "date",
                        locale: "fi-FI",
                        "prefer-wheel": false,
                        value: _ctx.getForIonDateTime(_ctx.ticketType.validityEnds),
                        onIonChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.ticketType.validityEnds = _ctx.setFromIonDateTime($event.detail.value))),
                        max: "2030"
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Eräpäivä laskulle")
                    ]),
                    _: 1
                  }),
                  (_ctx.ticketType.dueDate)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.ticketType.dueDate = undefined))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Poista")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.ticketType.dueDate)
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.ticketType.dueDate = new Date()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Aseta")
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.ticketType.dueDate)
                    ? (_openBlock(), _createBlock(_component_ion_datetime_button, {
                        key: 2,
                        datetime: "dueDate"
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_modal, { "keep-contents-mounted": true }, {
                default: _withCtx(() => [
                  (_ctx.ticketType.dueDate)
                    ? (_openBlock(), _createBlock(_component_ion_datetime, {
                        key: 0,
                        id: "dueDate",
                        presentation: "date",
                        locale: "fi-FI",
                        "prefer-wheel": false,
                        value: _ctx.getForIonDateTime(_ctx.ticketType.dueDate),
                        onIonChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.ticketType.dueDate = _ctx.setFromIonDateTime($event.detail.value))),
                        max: "2030"
                      }, null, 8, ["value"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_input, {
                    modelValue: _ctx.ticketType.price,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.ticketType.price) = $event)),
                    type: "number",
                    step: "0.01",
                    inputmode: "decimal",
                    label: "Hinta",
                    "label-placement": "stacked"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.ticketType.vatGroup,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.ticketType.vatGroup) = $event)),
                    placeholder: "Valitse alv-ryhmä",
                    multiple: false,
                    label: "ALV-ryhmä",
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vatGroups, (vat) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: vat.id,
                          value: vat.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(vat.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.ticketType.discountGroupId,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.ticketType.discountGroupId) = $event)),
                    placeholder: "Valitse alennusryhmä",
                    multiple: false,
                    label: "Alennusryhmä",
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.discountGroups, (group) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: group.id,
                          value: group.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(group.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.ticketType.discountGroups,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.ticketType.discountGroups) = $event)),
                    placeholder: "Valitse alennusryhmät",
                    multiple: true,
                    label: "Alennusryhmät joiden perusteella alennus",
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.discountGroups, (group) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: group.id,
                          value: group.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(group.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.ticketType.discounts,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.ticketType.discounts) = $event)),
                    placeholder: "Valitse alennukset",
                    multiple: true,
                    label: "Alennukset",
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.discountFiltered.sort((a,b)=>a.name.localeCompare(b.name)), (discount) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: discount.id,
                          value: discount.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(discount.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.ticketType.accountId,
                    "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.ticketType.accountId) = $event)),
                    placeholder: "Valitse tili",
                    multiple: false,
                    label: "Tiliöinti",
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accounts, (account) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: account.id,
                          value: account.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(account.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_select, {
                    modelValue: _ctx.ticketType.organizations,
                    "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.ticketType.organizations) = $event)),
                    placeholder: "Valitse toimipaikka",
                    multiple: true,
                    label: "Toimipaikat",
                    "label-placement": "stacked"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.organizations, (org) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: org.id,
                          value: org.id
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(org.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_checkbox, {
                    "label-placement": "start",
                    modelValue: _ctx.ticketType.isAvailable,
                    "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.ticketType.isAvailable) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Käytettävissä")
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_checkbox, {
                    "label-placement": "start",
                    modelValue: _ctx.ticketType.isWebSale,
                    "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.ticketType.isWebSale) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Netistä ostettavissa")
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_checkbox, {
                    "label-placement": "start",
                    modelValue: _ctx.ticketType.dateOfBirthRequired,
                    "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.ticketType.dateOfBirthRequired) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Syntymäaika kysytään")
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}