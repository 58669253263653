import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import { createPinia } from 'pinia';

import CKEditor from '@ckeditor/ckeditor5-vue';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { store } from "./store";
import { createI18n } from 'vue-i18n';

import enUS from './data/en-US.json'
import fiFI from './data/fi-FI.json'

// Type-define 'en-US' as the master schema for the resource
export type MessageSchema = typeof enUS

// define number format schema
export type NumberSchema = {
  currency: {
    style: 'currency'
    currencyDisplay: 'symbol'
    currency: string
  }
}

const i18n = createI18n<[MessageSchema], 'en-US' | 'fi-FI'>({
  legacy: false,
  locale: 'fi-FI',
  messages: {
    'en-US': enUS,
    'fi-FI': fiFI,
  }
})


const app = createApp(App)
  .use(IonicVue)
  .use(createPinia())
  .use(router)
  .use(store)
  .use(CKEditor);
  app.use(i18n);
router.isReady().then(() => {
  app.mount('#app');
});